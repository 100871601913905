import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CompanyService extends AbstractService {
    private controller = "companies";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public summary(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/summary`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public put(company: any, updateAddresses: boolean) {
        const d = { company, updateAddresses };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public currency() {
        const url = this.makeUrl(`${this.controller}/currency`);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}