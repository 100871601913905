/* BEGIN TRANSLATION */

const $vuetify = {
    noDataText: "No data available",
    dataTable: {
        itemsPerPageText: "Record Count",
        sortBy: "Sort By",
        ariaLabel: {
            sortNone: "No sorting",
            sortAscending: "Ascending order",
            sortDescending: "Descending order",
            activateNone: "None",
            activateAscending: "Ascending order",
            activateDescending: "Descending order"
        }
    },
    dataFooter: {
        prevPage: "Previous page",
        nextPage: "Next page",
        pageText: "{0}-{1} of {2}",
        itemsPerPageAll: "All"
    },
    dataIterator: {
        noResultsText: "No matching records found",
        loadingText: "Loading..."
    },
    fileInput: {
        counter: "{0} files",
        counterSize: "{0} files ({1} in total)"
    },
    pagination: {
        ariaLabel: {
            currentPage: "Current Page, Page {0}",
            next: "Next page",
            previous: "Previous page",
            wrapper: "Pagination Navigation",
			page: "Goto Page {0}"
        }
    },
    carousel: {
        ariaLabel: {
            delimiter: "Carousel slide {0} of {1}"
        },
        prev: "Previous visual",
        next: "Next visual"
    },
    datePicker: {
        prevMonthAriaLabel: "Previous month",
        nextMonthAriaLabel: "Next month"
    },
    timePicker: {
        am: "AM",
        pm: "PM"
    }
};

/* ENDED TRANSLATION */
export { $vuetify };