import { AxiosRequestConfig } from "axios";
import i18n from "@/locale/i18n";
import RouteUtil from "@/utilities/RouteUtil";

export default class AbstractService {
    protected makeConfig(hasBody: boolean = true, data: any = null): AxiosRequestConfig {
        var h = {
            headers: {
                'Accept-Language': i18n.locale
            }
        };
        
        if (hasBody) {
            h.headers['Content-Type'] = 'application/json';
        } 
        if (data !== null) {
            h['data'] = data;
        }

        return h;
    }

    protected makeUrl(page: string): string {
        return "/api/" + page;
    }
    protected makeUrlWithQueries(page: string, queries: { [key: string]: any | undefined }) {
        const url = this.makeUrl(page);
        return RouteUtil.makeUrl(url, queries);
    }
}