import Vue from "vue";
import VueRouter from "vue-router";
import AuthenticationService from "@/services/AuthenticationService";

Vue.use(VueRouter);

const ContactUsView = () => import("./components/ContactUsView.vue");
const HomeView = () => import("./components/home/HomeView.vue");
const MobileHomeView = () => import("./components/home/mobile/MobileHomeView.vue");
const AuthenticatingView = () => import("./components/AuthenticatingView.vue");
const ProfileView = () => import("./components/profile/ProfileView.vue");
const MobileProfileView = () => import("./components/profile/mobile/MobileProfileView.vue");
const LoginView = () => import("./components/LoginView.vue");
const NotFoundView = () => import("./error-pages/NotFoundView.vue");
const ManagementView = () => import("./components/management/ManagementView.vue");
const ManagementHomeView = () => import("./components/management/ManagementHomeView.vue");
const CompanyInfoView = () => import("./components/management/settings/company/CompanyInfoView.vue");
const SystemSettingInfoView = () => import("./components/management/settings/system-setting/SystemSettingInfoView.vue");
const RunningNumberListView = () => import("./components/management/settings/running-number/RunningNumberListView.vue");
const RunningNumberInfoView = () => import("./components/management/settings/running-number/RunningNumberInfoView.vue");
const UserListView = () => import("./components/management/common/user/UserListView.vue");
const UserInfoView = () => import("./components/management/common/user/UserInfoView.vue");
const CurrencyListView = () => import("./components/management/common/currency/CurrencyListView.vue");
const CurrencyInfoView = () => import("./components/management/common/currency/CurrencyInfoView.vue");
const CountryListView = () => import("./components/management/common/country/CountryListView.vue");
const CountryInfoView = () => import("./components/management/common/country/CountryInfoView.vue");
const StateListView = () => import("./components/management/common/state/StateListView.vue");
const StateInfoView = () => import("./components/management/common/state/StateInfoView.vue");
const CityListView = () => import("./components/management/common/city/CityListView.vue");
const CityInfoView = () => import("./components/management/common/city/CityInfoView.vue");
const CustomerNavigatorView = () => import("./components/management/customer/CustomerNavigatorView.vue");
const CustomerListView = () => import("./components/management/customer/CustomerListView.vue");
const CustomerInfoView = () => import("./components/management/customer/CustomerInfoView.vue");
const CustomerGroupListView = () => import("./components/management/customer/customer-group/CustomerGroupListView.vue");
const CustomerGroupInfoView = () => import("./components/management/customer/customer-group/CustomerGroupInfoView.vue");
const CustomerOrderListView = () => import("./components/management/customer/customer-order/CustomerOrderListView.vue");
const CustomerOrderInfoView = () => import("./components/management/customer/customer-order/CustomerOrderInfoView.vue");
const CustomerPaymentListView = () => import("./components/management/customer/customer-payment/CustomerPaymentListView.vue");
const CustomerPaymentInfoView = () => import("./components/management/customer/customer-payment/CustomerPaymentInfoView.vue");
const TransferRequestListView = () => import("./components/management/customer/transfer-request/TransferRequestListView.vue");
const TransferRequestInfoView = () => import("./components/management/customer/transfer-request/TransferRequestInfoView.vue");
const PostageView = () => import("./components/management/customer/postage/PostageView.vue");
const ShipmentView = () => import("./components/management/customer/shipment/ShipmentView.vue");
const VoucherListView = () => import("./components/management/sales/voucher/VoucherListView.vue");
const VoucherInfoView = () => import("./components/management/sales/voucher/VoucherInfoView.vue");
const ShippingFeeListView = () => import("./components/management/sales/shipping-fee/ShippingFeeListView.vue");
const ShippingFeeInfoView = () => import("./components/management/sales/shipping-fee/ShippingFeeInfoView.vue");
const LiveSessionListView = () => import("./components/management/live-session/LiveSessionListView.vue");
const LiveSessionInfoView = () => import("./components/management/live-session/standard/LiveSessionInfoView.vue");
const LiveSessionStreamerView = () => import("./components/management/live-session/streamer/LiveSessionStreamerView.vue");
const SessionClosingListView = () => import("./components/management/session-closing/SessionClosingListView.vue");
const SessionClosingInfoView = () => import("./components/management/session-closing/SessionClosingInfoView.vue");

const router = new VueRouter({
    mode: "history",
    routes: [
        { path: "", component: LoginView },
        { path: "/contact-us", component: ContactUsView },
        { path: "/home", component: HomeView, alias: ["/payment", "/processing", "/transfer"] },
        { path: "/m/home", component: MobileHomeView, alias: ["/m/payment", "/m/processing", "/m/transfer"] },
        { path: "/payment/:uniqueNo", component: HomeView, alias: ["/processing/:uniqueNo"] },
        { path: "/m/payment/:uniqueNo", component: MobileHomeView, alias: ["/m/processing/:uniqueNo"] },
        { path: "/transfer/:uniqueNo", component: HomeView },
        { path: "/m/transfer/:uniqueNo", component: MobileHomeView },
        { path: "/authenticating", component: AuthenticatingView },
        { path: "/profile", component: ProfileView },
        { path: "/m/profile", component: MobileProfileView },
        {
            path: "/management", component: ManagementView,
            children: [
                { path: "", component: ManagementHomeView },
                { path: "settings/company", component: CompanyInfoView },
                { path: "settings/system-setting", component: SystemSettingInfoView },
                { path: "settings/running-numbers/:mode", component: RunningNumberInfoView },
                { path: "settings/running-numbers", component: RunningNumberListView },
                { path: "common/users/:mode", component: UserInfoView },
                { path: "common/users", component: UserListView },
                { path: "common/currencies/:mode", component: CurrencyInfoView },
                { path: "common/currencies", component: CurrencyListView },
				{ path: "common/countries/:mode", component: CountryInfoView },
				{ path: "common/countries", component: CountryListView },
				{ path: "common/states/:mode", component: StateInfoView },
				{ path: "common/states", component: StateListView },
				{ path: "common/cities/:mode", component: CityInfoView },
				{ path: "common/cities", component: CityListView },
				{ path: "customers/navigator", component: CustomerNavigatorView },
                { path: "customers/:mode", component: CustomerInfoView },
                { path: "customers", component: CustomerListView },
                { path: "customer/customer-groups/:mode", component: CustomerGroupInfoView },
                { path: "customer/customer-groups", component: CustomerGroupListView },
                { path: "customer/customer-orders/:mode", component: CustomerOrderInfoView },
                { path: "customer/customer-orders", component: CustomerOrderListView },
                { path: "customer/customer-payments/:mode", component: CustomerPaymentInfoView },
                { path: "customer/customer-payments", component: CustomerPaymentListView },
                { path: "customer/transfer-requests/:mode", component: TransferRequestInfoView },
                { path: "customer/transfer-requests", component: TransferRequestListView },
                { path: "customer/postage", component: PostageView },
                { path: "customer/shipment", component: ShipmentView },
                { path: "sales/vouchers/:mode", component: VoucherInfoView },
                { path: "sales/vouchers", component: VoucherListView },
				{ path: "sales/shipping-fees/:mode", component: ShippingFeeInfoView },
				{ path: "sales/shipping-fees", component: ShippingFeeListView },
                { path: "live-sessions/streamer", component: LiveSessionStreamerView },
                { path: "live-sessions/:mode", component: LiveSessionInfoView },
                { path: "live-sessions", component: LiveSessionListView },
                { path: "session-closings/:mode", component: SessionClosingInfoView },
                { path: "session-closings", component: SessionClosingListView }
            ]
        },
        { path: "*", component: NotFoundView }
    ]
});

router.beforeEach(async (to, from, next) => {
    //next();
    if (to.path === '/contact-us') {
        next();
    } else {
        var authenticationService = new AuthenticationService();
        var r = await authenticationService.loggedIn();
        if (r.data) {
            if (to.path === '/') {
                next('/home');
            } else if (to.path.startsWith('/authenticating')) {
                if (to.query.type !== 'link' && to.query.type !== 'refresh') {
                    next('/home');
                } else {
                    next();
                }
            } else if (to.path.startsWith('/management')) {
                r = await authenticationService.administrator();
                if (!r.data) {
                    next('/home');
                } else {
                    next();
                }
            } else {
                next();
            }
        } else {
            if (to.path.startsWith('/authenticating')) {
                if (to.query.type !== 'login') {
                    next('/');
                } else {
                    next();
                }
            } else if (to.path !== '/') {
                next('/');
            } else {
                next();
            }
        }
    }
});

export default router;