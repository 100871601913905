














































































































































































import { Vue, Component } from "vue-property-decorator";
import { locale } from "@/locale/i18n";
import { ManagementLoginProperties } from "@/components/management/ManagementLoginView.vue";
import AuthenticationService from "@/services/AuthenticationService";
import CompanyService from "./services/CompanyService";
import store from "@/main-store";

const ManagementLoginView = () => import("@/components/management/ManagementLoginView.vue");

@Component({
    components: {
        ManagementLoginView,
    },
    data: () => ({
        name: "app",
        user: null,
        administrator: false,
        title: "",
        registrationName: "",
        registrationNo: "",
    }),
    computed: {
        managementVisible: {
            cache: false,
            get: function () {
                if (this.isLoginPage) {
                    return true;
                } else if (this.$data.administrator) {
                    return !this.isAuthenticatingPage && !this.isManagementPage;
                } else {
                    return false;
                }
            },
        },
        customerVisible: {
            cache: false,
            get: function () {
                if (this.isLoginPage || this.isAuthenticatingPage) {
                    return false;
                } else if (this.$data.administrator) {
                    return this.isManagementPage;
                } else {
                    return false;
                }
            },
        },
        isLoginPage: {
            cache: false,
            get: function () {
                const path = this.$route.path;
                return path === "/" || path === "";
            },
        },
        isManagementPage: {
            cache: false,
            get: function () {
                return this.$route.path.startsWith("/management");
            },
        },
        isAuthenticatingPage: {
            cache: false,
            get: function () {
                return this.$route.path.startsWith("/authenticating");
            },
        },        
        showHamburger: {
            cache: false,
            get: function() {
                if (!this.screenMedium) return false;
                const p = this.$route.path;
                const urls = [
                    "/m/home", 
                    "/m/payment",
                    "/m/transfer",
					"/m/processing",
                    "/m/profile"
                ];
                return urls.some(x => p.startsWith(x));
            }
        },
        profileUrl: {
            cache: false,
            get: function() {
                const p = this.screenMedium ? "/m" : "";
                return p + "/profile#main";
            }
        },
        screenSmall: function() {
            return this.$vuetify.breakpoint.smAndDown;
        },
        screenMedium: function() {
            return this.$vuetify.breakpoint.mdAndDown;
        }
    },
})
export default class App extends Vue {
    private managementLogin = new ManagementLoginProperties();
    private authenticationService = new AuthenticationService();
    private companyService = new CompanyService();

    public get languages() {
        return [
            { key: "en", text: "English" },
			{ key: "ms", text: "Malay" },
            { key: "zh", text: "中文" },
        ];
    }

    public get fullRegistrationName() {
        const d = new Date();
        const no = this.$data.registrationNo;
        const name = `© ${d.getFullYear()} ${this.$data.registrationName}`;
        return no ? name + ` (${no})` : name;
    }

    public get locale() {
        return this.$i18n.locale;
    }
    public set locale(v) {
        this.$cookies.set("lang", v, "9y");
        locale.changeLangauge(v);
    }

    public async created() {
        await this.loadCompany();

        store.events.subscribe("user", this.userLoaded);
        await store.loadUser();
    }

    public destroyed() {
        store.events.remove("user", this.userLoaded);
    }

    public async loadCompany() {
        try {
            const r = await this.companyService.summary();
            document.title = r.data.name;
            this.$data.registrationName = r.data.registrationName;
            this.$data.registrationNo = r.data.registrationNo;
            this.$data.title = document.title;
        } catch (e) {
            document.title = this.$t("title.app").toString();
            this.$data.registrationName = "";
            this.$data.registrationNo = "";
            this.$data.title = document.title;
        }
    }

    public async userLoaded(user: any) {
        this.$data.user = user;

        try {
            const r = await this.authenticationService.administrator();
            this.$data.administrator = r.data;

            if (!r.data && (this as any).isManagementPage) {
                await this.$router.push("/home");
            }
        } catch (e) {
            this.$data.administrator = false;
            console.log(e);
        }
    }

    private async logout() {
        await this.authenticationService.logout();
        await store.resetUser();
        await this.$router.push("/");
    }

    private async customerHome() {
        await this.$router.push("/home");
    }

    private async managementHome() {
        if ((this as any).isLoginPage) {
            this.managementLogin.visible = true;
        } else {
            await this.$router.push("/management");
        }
    }

    private async hamburger() {
        if (window.hamburger) {
            await window.hamburger();
        }
    }
}
