/* BEGIN TRANSLATION */

const message = {
    "login-failed": "Failed to login, please try again later",
    "login-required": "Please login to continue",
    "discard-confirmation": "Are you sure you want to discard current changes?",
    "cancel-order1": "Are you sure you want to cancel selected order?",
    "cancel-order2": "Are you sure you want to cancel selected order ({0})?",
    "reject-order": "Are you sure you want to reject selected order?",
    "finalize-order": "Are you sure you want to finalize selected order?",
    "delivered-order": "Are you sure you want to change the status to delivered?",
    "reverse-order": "Are you sure you want to reverse selected order?",
    "finalize-payment": "Are you sure you want to finalize selected payment?",
    "approve-payment": "Are you sure you want to approve selected payment?",
    "cancel-payment": "Are you sure you want to cancel selected payment?",
    "reject-payment": "Are you sure you want to reject selected payment?",
    "delivered-payment": "Are you sure you want to change the status to delivered?",
    "refunded-payment": "Are you sure you want to change the status to refunded?",
    "approve-transfer": "Are you sure you want to approve selected transfer?",
    "cancel-transfer": "Are you sure you want to cancel selected transfer?",
    "record-saved": "Record saved successfully",
    "record-deleted": "Record deleted successfully",
    "invalid-mode": "Invalid mode",
    "record-not-found": "Record not found",
    "no-address-added": "No address added",
    "address-city-required": "Address city required",
    "delete-record1": "Are you sure you want to delete selected record?",
    "delete-record2": "Are you sure you want to delete selected record ({0})?",
    "delete-record3": "Are you sure you want to delete current record?",
    "not-logged-in": "You are not logged in or session timeout, please login to continue",
    authenticating: "Authenticating, please wait...",
    "load-comment-failed": "Failed to load comment(s), retrying...",
    "link-facebook-confirmation": "Are you sure you want to link to your facebook account? You can't undo this later.",
    "link-account-confirmation": "Are you sure you want to link to '{0}'? You can't undo this later.",
    "facebook-not-linked": "You haven't link with your facebook account, please link to your account and try again later.",
    "account-not-selected": "please select an account to continue.",
    "live-session-nothing-selected": "Please select live session(s) to continue.",
    "link-account-successful": "Facebook account successfully linked.",
    "refresh-token-successful": "Facebook session has been refreshed due to the expiration time.",
    "no-account-found": "No available facebook account was found.",
    "no-video-found": "No available video was found.",
    "video-not-selected": "Please select a video to continue.",
    "nothing-selected": "Please select a record to continue.",
    "selected-video-confirmation": "Are you sure you want to use video '{0}', broadcasted on '{1}'?",
    "live-session-stop-confirmation": "Are you sure you want to end current session now?",
    "live-session-delete-confirmation": "Are you sure you want to delete current session?",
    "live-session-product-not-introducing": "No live session product is introducing now, open with default setting?",
    "order-accepted": "Order accepted ({0}, {1}, {2})",
    "key-in-order-quantity": "Please key in order quantity",
    "invalid-number": "Please key in a valid number and more than zero",
    "cart-quantity": "Order quantity / checkout quantity",
    "shipping-not-selected": "Please select shipping address.",
    "country-nothing-selected": "No country(ies) is selected",
    "checkout-nothing-selected": "Please select order(s) to checkout.",
    "transfer-nothing-selected": "Please select order(s) to transfer.",
    "state-nothing-selected": "No state(s) is selected",
    "city-nothing-selected": "No city(ies) is selected",
    "please-select-receipt": "Please select receipt(s) to upload.",
    "payment-pending-approval": "Thanks for your payments, your payment {0} is waiting for approval.",
    "transfer-pending-approval": "Transfer request received, {0} is waiting for approval.",
    "key-in-cancel-quantity": "Please key in quantity to cancel",
    "shipping-fee-pricing-description": "Every weight of {0} g will be priced at {1}.",
    "clipboard-copied": "Copied to clipboard.",
    "customer-group-event-conflict": "Current event setting is conflict with other.",
    "session-closing-all-notified": "All customer(s) has been notified.",
    "live-session-comment-date1": "{0} secs ago",
    "live-session-comment-date2": "{0} mins ago",
	"video-type-not-defined": "Video type not defined",
	"invalid-facebook-account-id": "Invalid facebook account",
	"order-reverted": "Order reverted",
	"revert-last-order-confirmation": "Are you sure you want to revert order ({0}, {1}, {2})?",
	"default-product-opened-successfully": "Default product opened successfully",
    "profile-close-account": {
        "w1": "Keep in mind that closing account will wipe your user data and you can't undo this action later.",
        "w2": "Please key in '",
        "w3": "' if you confirm your action.",
        "alert": "Key in 'Close Account' in order to continue to wipe your user data."
    }
};

/* ENDED TRANSLATION */
export { message };