import VueRouter from "vue-router";

export default class ExceptionUtil {
    public static async isAuthError(router: VueRouter, e: any) {
        console.log(e);
        if (e.response && e.response.data) {
            if (e.response.data.code === 'not-logged-in') {
                await router.push('/');
                return true;
            } else if (e.response.data.code === 'not-authorized') {
                await router.push('/home');
                return true;
            }
        } 
        return false;
    }

    public static getMessage(e: any) {
        console.log(e);
        if (e.response) {
            return e.response.data.message;
        } else {
            return e.message;
        }
    }
}