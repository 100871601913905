export default class RouteUtil {
    public static getUrlWithoutPathname(endedWithSlash: boolean = true) {
        var url = `${location.protocol}//${location.host}`;
        if (endedWithSlash && !url.endsWith("/")) {
            url += "/";
        } else if (!endedWithSlash && url.endsWith("/")) {
            url = url.substr(0, url.length - 1);
        }
        return url;
    }

    public static getUrlWithoutHashAndQuery(endedWithSlash: boolean = true) {
        var url = `${location.protocol}//${location.host + location.pathname}`;
        if (endedWithSlash && !url.endsWith("/")) {
            url += "/";
        } else if (!endedWithSlash && url.endsWith("/")) {
            url = url.substr(0, url.length - 1);
        }
        return url;
    }

    public static makeUrl(url: string, queries: { [key: string]: any | undefined }) {
        var first = true;
        for (var k in queries) {
            const v = this.queryEncode(queries[k]);
			if (v === "") continue;

            if (first) {
                url += `?${k}=${v}`;
                first = false;
            } else {
                url += `&${k}=${v}`;
            }
        }

        return url;
    }

	private static queryEncode(v: any): string {
		if ((v ?? null) === null) return "";
		
		if (Array.isArray(v)) {
			return (<any[]>v)
				.map(x => this.queryEncode(x))
				.filter(x => x !== "")
				.join(",");
		} else {
			v = v instanceof Date ? v.getTime() : v;
			return encodeURIComponent(v);
		}
	}
}