import AbstractService from './AbstractService';
import Axios from "axios";

export default class AuthenticationService extends AbstractService {
    private controller = "authentication";

    public login(username: string, password: string) {
        const d = { username, password };
        const url = this.makeUrl(`${this.controller}/login`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public loginUser() {
        const url = this.makeUrl(`${this.controller}/login-user`);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public loggedIn() {
        const url = this.makeUrl(`${this.controller}/logged-in`);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public logout() {
        const url = this.makeUrl(`${this.controller}/logout`);
        return Axios.post<any>(url, this.makeConfig(false));
    }

    public administrator() {
        const url = this.makeUrl(`${this.controller}/administrator`);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}