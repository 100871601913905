/* BEGIN TRANSLATION */

const title = {
    app: "Live Assistant",
    information: "Information",
    "error-occurred": "Error Occurred",
    "management-login": "Management Login",
    "running-number": "Running Number",
    "system-setting": "System Setting",
    company: "Company",
    "company-address": "Company Address",
    user: "User",
    customer: "Customer",
    "customer-group": "Customer Group",
    "discard-changes": "Discard Changes",
    "delete-record": "Delete Record",
    create: "Create",
    modify: "Modify",
    profile: "Profile",
    address: "Address",
    "new-address": "New Address",
    "edit-address": "Edit Address",
    "customer-address": "Customer Address",
    "live-session": "Live Session",
    confirmation: "Confirmation",
    "facebook-account": "Facebook Account",
    currency: "Currency",
    "session-closing": "Session Closing",
    "product-sales": "Product Sales",
    "import-live-session-detail": "Import Live Session Detail",
    "notify-customer": "Notify Customer",
    "shipping-address": "Shipping Address",
    checkout: "Checkout",
    payment: "Payment",
    "customer-order": "Customer Order",
    "customer-payment": "Customer Payment",
    attachments: "Attachments",
	country: "Country",
	state: "State",
    city: "City",
    order: "Order",
    "shipping-fee": "Shipping Fee",
    voucher: "Voucher",
    pricing: "Pricing",
    "payment-detail": "Payment Detail",
    "cancel-order": "Cancel Order",
    "finalize-order": "Finalize Order",
    "reject-order": "Reject Order",
    "reverse-order": "Reverse Order",
    "transfer-request": "Transfer Request",
    "finalize-payment": "Finalize Payment",
    "approve-payment": "Approve Payment",
    "cancel-payment": "Cancel Payment",
    "approve-transfer": "Approve Transfer",
    "cancel-transfer": "Cancel Transfer",
    transfer: "Transfer",
    event: "Event",
    "wallet-balance": "Wallet Balance",
    "customer-payment-summary": "Customer Payment Summary",
    "upload-receipt": "Upload Receipt",
    postage: "Postage",
    shipment: "Shipment",
    "expiration-date": "Expiration Date",
    "change-password": "Change Password",
    /* Saperator */
    processing: "Processing"
};

/* ENDED TRANSLATION */
export { title };