

























import { Vue, Prop, Component } from "vue-property-decorator";
import { TranslateResult } from "vue-i18n";
import { AlertDialogProperties } from "./AlertDialogProperties";

export { AlertDialogProperties };

@Component({})
export default class AlertDialogView extends Vue {
    @Prop({ default: new AlertDialogProperties() }) 
    public properties: AlertDialogProperties;

    private clicked(button: string | TranslateResult) {
        this.$emit("clicked", button);
    }

    private closed() {
        this.$emit("closed");
    }
}
