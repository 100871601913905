import AuthenticationService from "@/services/AuthenticationService";
import EventManager from "@/utilities/EventManager";

var store = new class {
    private authenticationService = new AuthenticationService();
    private user: any = null;
    private userLoaded = false;
    public events = new EventManager();

    public getUser() {
        return this.user;
    }

    public isUserLoaded() {
        return this.userLoaded;
    }

    public async reloadUser() {
        this.userLoaded = false;
        this.user = null;
        return await this.loadUser();
    }

    public async loadUser() {
        if (!this.userLoaded) {
            var r = await this.authenticationService.loginUser();
            this.user = r.data;
            this.userLoaded = true;
        }
        await this.events.fire('user', this.user);
        return this.user;
    }

    public async resetUser() {
        this.userLoaded = false;
        this.user = null;
        await this.events.fire('user', null);
    }
};
//window.tag = store;

export default store;