import Vue from "vue";
import VueI18n from 'vue-i18n';
import en from "./en";
//import EventManager from '@/utilities/EventManager';

Vue.use(VueI18n);

var i18n = new VueI18n({
    locale: "en",
    messages: {
        "en": en
    }
});

class Locale {
    private loaded = ['en'];
    //public events = new EventManager();

    public changeLangauge(lang: string) {
        if (i18n.locale === lang) return; 
        if (this.loaded.includes(lang)) {
            i18n.locale = lang;
            //this.events.fire('language', lang);
            return;
        }

        import(`@/locale/${lang}`).then(r => {
            var d = r.default;
            i18n.setLocaleMessage(lang, d);
            this.loaded.push(d.default);
            i18n.locale = lang;
            //this.events.fire('language', lang);
            document.querySelector('html').setAttribute('lang', lang);
        });
    }
}
const locale = new Locale();

export { locale };
export default i18n;