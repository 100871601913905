export default class EventManager {
    private events: { [event: string]: any[] } = {};

    public subscribe(event: string, callback: any) {
        var callbacks = this.events[event];
        if (!callbacks) {
            callbacks = this.events[event] = [];
        }
        callbacks.push(callback);
    }

    public remove(event: string, callback: any) {
        const callbacks = this.events[event];
        if (!callbacks) return;
        const index = callbacks.indexOf(callback);

        if (index !== -1) { 
            callbacks.splice(index, 1);
        }
    }

    public async fire(event: string, ...args: any) {
        const callbacks = this.events[event];
        if (!callbacks) return;
        const results = new Array(callbacks.length);
        
        for (var i = 0; i < callbacks.length; i++) {
            results[i] = await Promise.resolve(callbacks[i].apply(null, args));
        }
        return results;        
    }
}