






































import { Vue, Component, Prop } from "vue-property-decorator";
import AuthenticationService from "@/services/AuthenticationService";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import store from "@/main-store";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

class ManagementLoginProperties {
    visible: boolean = false;
}

export { ManagementLoginProperties };

@Component({
    components: { AlertDialogView },
    data: () => ({
        username: "",
        password: "",
        visible: false
    })
})
export default class ManagementLoginView extends Vue {
    @Prop({ default: new ManagementLoginProperties() })
    public properties: ManagementLoginProperties;
    private alertDialog = new AlertDialogProperties();
    private authenticationService = new AuthenticationService();

    public async login() {
        try {
            await this.authenticationService.login(
                this.$data.username,
                this.$data.password
            );
            this.properties.visible = false;
            await store.resetUser();
            await store.loadUser();
            await this.$router.push("/management");
        } catch (e) {
            var m = ExceptionUtil.getMessage(e);
            AlertDialogProperties.error(this.alertDialog, null, m);
            this.alertDialog.visible = true;
        }
    }

    public cancel() {
        this.properties.visible = false;
    }

    public alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
    }
}
