import { TranslateResult } from "vue-i18n";
import i18n from "@/locale/i18n";

class AlertDialogProperties {
    visible: boolean = false;
    title: string | TranslateResult = "";
    message: string | TranslateResult = "";
    buttons: (string | TranslateResult)[] = [];
    primaryButton: string | TranslateResult = "";
    persistent: boolean = false;
    maxWidth: string = "";
    tag: any = null;

    public static standard(
        properties: AlertDialogProperties,
        title: string | TranslateResult | any,
        message: string | TranslateResult | any
    ) {
        properties.title = title;
        properties.message = message;
        properties.buttons = [];
        properties.primaryButton = null;
        properties.maxWidth = "550px";
        properties.tag = null;
    }

    public static delete(
        properties: AlertDialogProperties,
        info: string | any = ""
    ) {
        AlertDialogProperties.standard(properties, null, null);
        properties.title = i18n.t("title.delete-record");
        if ((info || "") !== "") {
            properties.message = i18n.t("message.delete-record2", [info]);
        } else {
            properties.message = i18n.t("message.delete-record1");
        }

        properties.buttons = [i18n.t("text.yes"), i18n.t("text.no")];
        properties.primaryButton = i18n.t("text.no");
    }

    public static info(
        properties: AlertDialogProperties,
        title: String | TranslateResult | any,
        message: string | TranslateResult
    ) {
        AlertDialogProperties.standard(properties, null, message);
        properties.title = title || i18n.t("title.information");
        properties.buttons = [i18n.t("text.ok")];
        properties.primaryButton = i18n.t("text.ok");
    }

    public static error(
        properties: AlertDialogProperties,
        title: string | TranslateResult | any,
        message: string | TranslateResult
    ) {
        AlertDialogProperties.standard(properties, null, message);
        properties.title = title || i18n.t("title.error-occurred");
        properties.buttons = [i18n.t("text.ok")];
        properties.primaryButton = i18n.t("text.ok");
    }

    public static saved(
        properties: AlertDialogProperties,
        title: string | TranslateResult | any,
        message: string | TranslateResult | any
    ) {
        AlertDialogProperties.standard(properties, null, null);
        properties.title = title || i18n.t("title.information");
        properties.message = message || i18n.t("message.record-saved");
        properties.buttons = [i18n.t("text.ok")];
        properties.primaryButton = i18n.t("text.ok");
    }

    public static deleted(
        properties: AlertDialogProperties,
        title: string | TranslateResult | any,
        message: string | TranslateResult
    ) {
        AlertDialogProperties.standard(properties, null, null);
        properties.title = title || i18n.t("title.information");
        properties.message = message || i18n.t("message.record-deleted");
        properties.buttons = [i18n.t("text.ok")];
        properties.primaryButton = i18n.t("text.ok");
    }

    public static discard(
        properties: AlertDialogProperties,
        title: string | TranslateResult | any,
        message: string | TranslateResult | any
    ) {
        AlertDialogProperties.standard(properties, null, null);
        properties.title = title || i18n.t("title.discard-changes");
        properties.message = message || i18n.t("message.discard-confirmation");
        properties.buttons = [i18n.t("text.yes"), i18n.t("text.no")];
        properties.primaryButton = i18n.t("text.yes");
    }
}

export { AlertDialogProperties };