import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from "../locale/i18n";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
   directives: {
      Ripple
   }
});

export default new Vuetify({
   lang: {
      t: (key, ...params) => i18n.t(key, params).toString()
   }
});