import { Component, Vue } from "vue-property-decorator";
import { CreateElement } from 'vue/types/umd';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n, { locale } from "./locale/i18n";
import router from "./main-router";
import VueCookies from "vue-cookies";
import VueMeta from "vue-meta";

Vue.config.productionTip = true;

Vue.use(VueCookies);
Vue.use(VueMeta);

var lang = Vue.$cookies.get('lang');
if (lang) { 
    locale.changeLangauge(lang);
}

declare global {
    interface Window {
        vue: Vue;
        tag: any;
        component: any;
        hamburger: any;
    }
}

@Component({
    vuetify,
    router,
    i18n
})
export default class Main extends Vue {

    public findComponent(name: string) {
        return this.findComponentRecursive(this, name);
    }

    private findComponentRecursive(vue: Vue, name: string): any {
        if (vue.$data.name === name) {
            return vue;
        }

        for (var i = 0; i < vue.$children.length; i++) {
            var result = this.findComponentRecursive(vue.$children[i], name);
            if (result !== null) {
                return result;
            }
        }

        return null;
    }

    public render(h: CreateElement) {
        return h(App);
    }
}

performMount();

function performMount() {
    window.vue = new Main();
    window.vue.$mount('#app');
}
