/* BEGIN TRANSLATION */

const text = {
    ok: "Ok",
    yes: "Yes",
    no: "No",
    login: "Login",
    cancel: "Cancel",
    profile: "Profile",
    logout: "Logout",
    "login-with-facebook": "Login with Facebook",
    settings: "Settings",
    username: "Username",
    password: "Password",
    company: "Company",
    code: "Code",
    name: "Name",
    management: "Management",
    customer: "Customer",
    "registration-no": "Registration No",
    "tax-registration-no": "Tax Registration No",
    "discard-changes": "Discard Changes",
    "save-changes": "Save Changes",
    "delete-record": "Delete Record",
    address: "Address",
    "printing-address": "Printing Address",
    actions: "Actions",
    close: "Close",
    submit: "Submit",
    line1: "Line 1",
    line2: "Line 2",
    line3: "Line 3",
    line4: "Line 4",
    postcode: "Postcode",
    city: "City",
    state: "State",
    country: "Country",
    email: "Email",
    "contact-no": "Contact No",
    "contact-person": "Contact Person",
    create: "Create",
    modify: "Modify",
    "delete-selected": "Delete Selected",
    "running-number": "Running Number",
    "record-count": "Record Count",
    refresh: "Refresh",
    delete: "Delete",
    format: "Format",
    length: "Length",
    sample: "Sample",
    "reset-by": "Reset By",
    none: "None",
    daily: "Daily",
    monthly: "Monthly",
    yearly: "Yearly",
    "change-password": "Change Password",
    "current-password": "Current Password",
    "system-setting": "System Setting",
    "full-name": "Full Name",
    "facebook-linked": "Facebook Linked",
    user: "User",
    "new-password": "New Password",
    "confirm-password": "Confirm Password",
    "create-record": "Create New",
    "finalize-record": "Finalize Record",
    birthday: "Birthday",
    gender: "Gender",
    male: "Male",
    female: "Female",
    "customer-group": "Customer Group",
    "purchase-amount-limit": "Purchase Amount Limit",
    "purchase-quantity-limit": "Purchase Quantity Limit",
    back: "Back",
    "address-book": "Adress Book",
    "shipping-address": "Shipping Address",
    sales: "Sales",
    "live-session": "Live Session",
    "session-date": "Session Date",
    "new-session": "New Session",
    "start-session": "Start Session",
    "end-session": "End Session",
    status: "Status",
    "live-session-info": "Live Session Info",
    comment: "Comment",
    "comment-count": "Comment Count",
    hide: "Hide",
    "link-facebook": "Link with Facebook",
    "product-introducing": "Product Introducing",
    "product-code": "Product Code",
    "product-name": "Product Name",
    "weight-g": "Weight (g)",
    "total-weight-g": "Total Weight (g)",
    quantity: "Quantity",
    "available-quantity": "Available Quantity",
    price: "Price",
    open: "Open",
    ended: "Ended",
    video: "Video",
    "confirmation-dialog": "Confirmation Dialog",
    "history-count": "History Count",
    account: "Account",
    "facebook-account": "Facebook Account",
    "facebook-url": "Facebook Url",
    link: "Link",
    "started-date": "Started Date",
    "ended-date": "Ended Date",
    bidding: "Bidding",
    remarks: "Remarks",
    deposit: "Deposit",
    save: "Save",
    send: "Send",
    "accept-order": "Accept Order",
    ordered: "Ordered",
    symbol: "Symbol",
    common: "Common",
    currency: "Currency",
    currencies: "Currencies",
    "total-amount": "Total Amount",
    "live-sessions": "Live Sessions",
    "product-sales": "Product Sales",
    customers: "Customers",
    "closing-no": "Closing No",
    "closing-date": "Closing Date",
    "session-closing": "Session Closing",
    "finalized-date": "Finalized Date",
    "add-live-session": "Add Live Session",
    import: "Import",
    "import-all": "Import All",
    "line-no": "No.",
    "total-quantity": "Total Quantity",
    notify: "Notify",
    "notify-all": "Notify All",
    message: "Message",
    notified: "Notified",
    notification: "Notification",
    "expires-at": "Expires At",
    "password-enabled": "Password Enabled",
    summary: "Summary",
    subtotal: "Subtotal",
    "shipping-fee": "Shipping Fee",
    checkout: "Checkout",
    "payment-method": "Payment Method",
    receipt: "Receipt",
    wallet: "Wallet",
    mixed: "Mixed",
    products: "Products",
    payment: "Payment",
    "payment-no": "Payment No",
    "payment-date": "Payment Date",
    "login-enabled": "Login Enabled",
    administrator: "Administrator",
    "user-info": "User Info",
    "customer-order": "Customer Order",
    "customer-code": "Customer Code",
    "customer-name": "Customer Name",
    "pending-amount": "Pending Amount",
    "customer-payment": "Customer Payment",
    fullscreen: "Fullscreen",
    description: "Description",
    regions: "Regions",
    "all-countries": "All Countries", 
    "all-states": "All States",
    "all-cities": "All Cities",
    "add-region": "Add Region",
    addresses: "Addresses",
    "capture-order": "Capture Order",
    "capture-format": "Capture Format",
    "comment-name-color": "Comment Name Color",
    "comment-message-color": "Comment Message Color",
    "comment-background-color": "Comment Background Color",
    "comment-tag-color": "Comment Tag Color",
    voucher: "Voucher",
    "voucher-length": "Voucher Length",
    "discount-target": "Discount Target",
    "discount-overall": "Overall (Subtotal & Shipping Fee)",
    "discount-amount": "Discount Amount",
    "discount-percent": "Discount Percent",
    "maximum-discount": "Maximum Discount",
    "minimum-order-amount": "Minimum Order Amount",
    "maximum-order-amount": "Maximum Order Amount",
    "minimum-total-amount": "Minimum Total Amount",
    "maximum-total-amount": "Maximum Total Amount",
    "payment-amount": "Payment Amount",
    "repeat-usage": "Repeat Usage",
    discontinued: "Discontinued",
    pricing: "Pricing",
    "weight-step-g": "Weight Step (g)",
    "standard-delivery": "Standard Delivery",
    "on-hold-delivery": "On Hold Delivery",
    "store-pickup": "Store Pickup",
    orders: "Orders",
    "receipt-payment": "Receipt Payment",
    "wallet-payment": "Wallet Payment",
    "total-payment": "Total Payment",
    details: "Details",
    order: "Order",
    "order-no": "Order No",
    "order-date": "Order Date",
    "customer-order-detail": "Customer Order Detail",
    draft: "Draft",
    "to-pay": "To Pay",
    processing: "Processing",
    paid: "Paid",
    delivered: "Delivered",
    cancelled: "Cancelled",
    expired: "Expired",
    rejected: "Rejected",
    completed: "Completed",
    "cancel-order": "Cancel Order",
    "finalize-order": "Finalize Order",
    "reject-order": "Reject Order",
    "move-record": "Move Record",
    existing: "Existing",
    "new-order": "New Order",
    "sub-order": "Sub Order",
    "new-order-status": "New Order Status",
    "approve-payment": "Approve Payment",
    "cancel-payment": "Cancel Payment",
    "reject-payment": "Reject Payment",
    "finalize-payment": "Finalize Payment",
    "cash-payment": "Cash Payment",
    "wallet-balance": "Wallet Balance",
    refunded: "Refunded",
    "select-all": "Select All",
    type: "Type",
    "generate-token": "Generate Token",
    token: "Token",
    miscellaneous: "Miscellaneous",
    "customer-token-expires-minutes": "Customer Token Expires (Minutes)",
    never: "Never",
    copy: "Copy",
    "request-no": "Request No",
    "request-date": "Request Date", 
    "request-by-code": "Request By Code",
    "request-by-name": "Request By Name",
    "transfer-to-code": "Transfer To Code",
    "transfer-to-name": "Transfer To Name",
    "transfer-order": "Transfer Order",
    transfer: "Transfer",
    "selected-orders": "Selected Orders",
    "transfer-token": "Transfer Token",
    "transfer-request": "Transfer Request",
    "request-by": "Request By",
    "transfer-to": "Transfer To",
    "approve-transfer": "Approve Transfer",
    "cancel-transfer": "Cancel Transfer",
    view: "View",
    "date-format": "Date Format",
    "time-format": "Time Format",
    search: "Search",
    clear: "Clear",
    filter: "Filter",
    all: "All",
    preparing: "Preparing",
    started: "Started",
    closed: "Closed",
    transferred: "Transferred",
    finalized: "Finalized",
    select: "Select",
    generate: "Generate",
    "print-receipt": "Print Receipt",
    "date-from": "Date From",
    "date-to": "Date To",
    "empty-date": "Empty Date",
    "voucher-numbers": "Voucher Numbers",
    "voucher-number": "Voucher Number",
    "delivery-method": "Delivery Method",
    approved: "Approved",
    transferring: "Transferring",
    "transfer-date": "Transfer Date",
    "customer-group-event": "Customer Group Event",
    events: "Events",
    "order-extend-expires": "Order Extend Expires (days)",
    "deposit-extend-expires": "Deposit Extend Expires (days)",
    "checkout-extend-expires": "Checkout Extend Expires (days)",
    "cancelled-extend-expires": "Cancelled Extend Expires (days)",
    "order-extend-count": "Order Extend Count",
    "deposit-extend-count": "Deposit Extend Count",
    "checkout-extend-count": "Checkout Extend Count",
    "cancelled-extend-count": "Cancelled Extend Count",
    disabled: "Disabled",
    rank: "Rank",
    "current-balance": "Current Balance",
    "adjustment-amount": "Adjustment Amount",
    "adjusted-balance": "Adjusted Balance",
    adjust: "Adjust",
    reason: "Reason",
    cash: "Cash",
    expand: "Expand",
    "add-order": "Add Order",
    upload: "Upload",
    "comment-message": "Comment Message",
    "session-no": "Session No",
    postage: "Postage",
    shipment: "Shipment",
    "product-price": "Product Price",
    "product-weight-g": "Product Weight (g)",
    "expiration-date": "Expiration Date",
    reverse: "Reverse",
    "allowed-delivery-methods": "Allowed Delivery Methods",
    "standard-product-prices": "Standard Product Prices",
    unnamed: "Unnamed",
    "contact-us": "Contact Us",
    "registration-name": "Registration Name",
    "our-store": "Our Store",
    "our-facebook-page": "Our Facebook Page",
    "email-address": "Email Address",
	"comment-info": "Comment Info",
	"search-comment": "Search Comment",
    "login-history": "Login History",
    "login-date": "Login Date",
    "login-source": "Login Source",
	exclude: "Exclude",
    standard: "Standard",
    facebook: "Facebook",
    streamer: "Streamer",
    "auto-reload": "Auto Reload",
    "comment-date": "Comment Date",
    reply: "Reply",
    pending: "Pending",
    "record-date": "Record Date",
	psid: "PSID",
	"request-by-psid": "Request By PSID",
	"transfer-to-psid": "Transfer To PSID",
	"video-selection": "Video Selection",
	"live-videos": "Live Videos",
	"prev-videos": "Previous Videos",
	"exclude-linked": "Exclude Linked",
	"preset-product": "Preset Product",
	"conversation-url": "Conversation Url",
	navigating: "Navigating",
	navigate: "Navigate",
	"name-history": "Name History",
	export: "Export",
	"created-date": "Created Date",
	"exclude-accepted": "Exclude Accepted",
	"more": "More",
	"enabled": "Enabled",
	"revert-last-order": "Revert Last Order",
	"notify-template": "Notify Template",
	"notify-customer": "Notify Customer",
	"imtbot-access-token": "Imtbot Access Token",
    "close-account": "Close Account",
    "confirmation": "Confirmation",
    "infinite": "Infinite"
};

/* ENDED TRANSLATION */
export { text };