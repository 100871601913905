/* BEGIN TRANSLATION */

const hint = {
    format: "%yy / %yyyy - year, %mm - month, %dd - day, %nn - numbering",
    "can-be-multiple": "Can be multiple selection",
    "token-infinite": "Token doesn't expired",
    "token-expires": "Token expires at {0}"
};

/* ENDED TRANSLATION */
export { hint };